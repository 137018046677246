import { BrandContext } from "@components/brand-context/brand-context";
import { Loading } from "@components/loading";
import { CacheProvider } from "@emotion/react";
import {
  CssBaseline,
  GlobalStyles,
  type Theme,
  ThemeProvider,
} from "@infinitaslearning/pixel-design-system";
import { getAnalyticsTracker, initializeAnalyticsTracker } from "@lib/analytics-tracker";
import { queryClient } from "@lib/react-query";
import { setLocalSession } from "@lib/session-store";
import { LoggedOutView } from "@components/logged-out-view";
import type { AppPropsWithLayout } from "@pages/types";
import createEmotionCache from "@styles/createEmotionCache";
import GlobalStyle from "@styles/global-styling/global.styles";
import { getPixelBrandTheme } from "@styles/theme";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getEnvironment, isPlatformBrowser } from "@utils/common";
import { getOpCoWithLocale, useBrand } from "@utils/use-brand";
import useServiceWorker from "@utils/use-service-worker";
import { SessionProvider, useSession } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import type { AppContext } from "next/app";
import App from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { initObservabilityWeb } from "@lib/observability";
import dynamic from "next/dynamic";

const environment = getEnvironment();
// we want to setup the observability module as soon as possible
initObservabilityWeb(environment);

const inputGlobalStyles = (theme: Theme) => (
  <GlobalStyles
    styles={{
      body: {
        height: "100%",
        background: theme.pixel.color.background.key,
      },
      html: {
        height: "100%",
      },
    }}
  />
);

const resetTheme = (theme: Theme): Theme => ({
  ...theme,
  components: {
    ...theme.components,
    MuiCssBaseline: {
      ...theme.components?.MuiCssBaseline,
    },
  },
});

const clientSideEmotionCache = createEmotionCache();

let hostname = undefined;
if (isPlatformBrowser()) {
  hostname = window?.location?.hostname;
}

if (isPlatformBrowser() && process.env.NODE_ENV !== "test") {
  const { opCo, locale } = getOpCoWithLocale();
  // Set HTML lang from here so that the lang attribure set in _document.page.tsx gets overwritten
  // That way the automatic translate suggestion will no longer show up
  document.documentElement.lang = locale;

  await initializeAnalyticsTracker({
    environment:
      process.env.NODE_ENV === "production" && hostname !== "localhost" ? "production" : "local",
    opCo,
    platform: "PEP",
    platformVariant: "teacher",
  });
}

const ID_TOKEN_COOKIE_NAME = "id_token";
const SIGNED_IN_COOKIE_NAME = "signed_in";

type PagesProps = {
  Component: AppPropsWithLayout["Component"];
  pageProps: AppPropsWithLayout["pageProps"];
};

const DynamicLoggedInView = dynamic(
  () => import("@components/logged-in-view").then((mod) => ({ default: mod.LoggedInView })),
  { ssr: false },
);

const Pages = ({ Component, pageProps }: PagesProps) => {
  const router = useRouter();
  const [_, setCookie, removeCookie] = useCookies([ID_TOKEN_COOKIE_NAME, SIGNED_IN_COOKIE_NAME]);
  const { data, status } = useSession({
    // needs to be true to be able to use the `onUnauthenticated` callback
    required: true,
    onUnauthenticated: () => {
      // This is kinda of funny and very sad at the same time.
      // If we remove this `onUnauthenticated` callback, the `useSession` of next-auth will
      // make our app loop in a infinite loop of redirects (not always, for example in e2e tests).
      // So, at least until we don't find a better solution, we need to keep this callback here.
      // I hope this comment will help the next developer to understand why this is here because I lost more than 1 day
      // and almost cry because of this
    },
  });

  setLocalSession(data, status);

  useEffect(() => {
    if (data?.idToken) {
      const cookieOptions = {
        path: "/",
        expires: new Date(data?.expires as number),
        secure: true,
        sameSite: "strict",
      } as const;
      // we need to make the idToken (jwt) available to embedded player services like linguineo/slimstampen
      setCookie(ID_TOKEN_COOKIE_NAME, data?.idToken, cookieOptions);
      setCookie(SIGNED_IN_COOKIE_NAME, true, cookieOptions);
    }
    return () => {
      removeCookie(ID_TOKEN_COOKIE_NAME);
      removeCookie(SIGNED_IN_COOKIE_NAME);
    };
  }, [data, removeCookie, setCookie]);

  // status will never be "unauthorized" because we use `required: true` on useSession
  if (status === "loading") {
    if (
      router.pathname.startsWith("/unauthenticated") ||
      router.pathname.startsWith("/unauthorized")
    ) {
      return <LoggedOutView Component={Component} pageProps={pageProps} />;
    }

    return <Loading />;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <DynamicLoggedInView Component={Component} pageProps={pageProps} />
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </QueryClientProvider>
    </>
  );
};

const PEPTeacher = ({
  Component,
  pageProps,
  // @ts-ignore: this is failing due to emotion using different versions of emotion cache for some reason. Though the difference is small enough to call ignore here.
  // TODO check if this ignore can be removed when emotion is updated again
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout) => {
  const brand = useBrand(pageProps.hostname);
  const theme = getPixelBrandTheme(brand.opCo);
  const custTheme = resetTheme(theme);
  const sessionRefetchInterval = 6 * 60 * 60; // 6 hours
  const scrollbarColor = custTheme.pixel.color.onSurface.variant;

  useEffect(() => {
    const tracker = getAnalyticsTracker();
    tracker?.awaitInitialized?.().then(() => tracker.listen());
  }, []);

  useServiceWorker();

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="description" content="PEP" />
          <link key="favicon" rel="icon" href={`/favicon-${brand.opCo}.ico`} />
        </Head>
        <CookiesProvider>
          <SessionProvider
            refetchInterval={sessionRefetchInterval}
            session={pageProps.session}
            refetchOnWindowFocus={false}
          >
            <ThemeProvider theme={custTheme}>
              <CssBaseline />
              {inputGlobalStyles(custTheme)}
              <GlobalStyle scrollbarColor={scrollbarColor} />
              <BrandContext.Provider value={brand}>
                <Pages Component={Component} pageProps={pageProps} />
              </BrandContext.Provider>
            </ThemeProvider>
          </SessionProvider>
        </CookiesProvider>
      </CacheProvider>
    </>
  );
};

PEPTeacher.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  appProps.pageProps.hostname = appContext?.ctx?.req?.headers?.host?.split(":")[0] || "";

  return { ...appProps };
};

export default appWithTranslation(PEPTeacher);
